import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
};

const slice = createSlice({
  name: 'bundle',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// * get bundle
// * statusType = 'active' || 'inactive' || 'archived'
export function getBundles(query, statusType = 'active', sort = 'createdAt') {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      // if (query) {
      //   response = await axios.get(`/bundle/all?status=${statusType}`, { params: query });
      // } else {
      //   response = await axios.get(`/bundle/all?status=${statusType}`);
      // }
      response = await axios.post(`/bundle/all?status=${statusType}`, { query, sort });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// *create bundle
export function createBundle(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.post('/bundle/create', data);
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// * delete bundle
export function deleteBundle(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.delete(`/bundle/${id}`);
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// * update bundle
export function updateBundle(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/bundle/${id}`, data);
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// * archive bundle by updated the status to 'archived'
export function archiveBundle(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.put(`/bundle/${id}`, {
        status: 'archived',
      });
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// * get bundle by id
export function getBundleById(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(`/bundle/${id}`);
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// * get stats
export function getStats() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/stats');
      if (response.data) {
        dispatch(slice.actions.stopLoading());
        return response.data;
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name },
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getAllActivities(filter) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      // if (query) {
      //   response = await axios.get(`/bundle/all?status=${statusType}`, { params: query });
      // } else {
      //   response = await axios.get(`/bundle/all?status=${statusType}`);
      // }
      response = await axios.post(`/activity/all`, { query: filter });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}
