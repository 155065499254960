// @mui
import { alpha, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function CssBaseline() {
  const theme = useTheme();
  const track = '#202022';
  const thumb = '#585859';
  const active = '#838384';
  return {
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100%',
        },
        input: {
          '&[type=number]': {
            MozAppearance: 'textfield',
            '&::-webkit-outer-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
            '&::-webkit-inner-spin-button': {
              margin: 0,
              WebkitAppearance: 'none',
            },
          },
        },
        img: {
          display: 'block',
          maxWidth: '100%',
        },
        '*::-webkit-scrollbar': {
          // backgroundColor: track,
          backgroundColor: alpha(theme.palette.grey[600], 0.48),
          width: 10,
        },
        '*::-webkit-scrollbar-thumb': {
          borderRadius: 8,
          backgroundColor: alpha(theme.palette.grey[900], 0.48),
          height: 6,
          // border: `3px solid ${track}`,
        },
      },
    },
  };
}
