import { createContext, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  },
  LOGIN: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
    };
  },
  UPDATE: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      user,
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  update: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  verifyOTPCode: () => Promise.resolve(),
  updatePassword: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get('/account');

          const { data: user } = response.data;

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post('/login', {
      email,
      password,
    });
    const { token: accessToken, data: user } = response.data;

    setSession(accessToken);
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
      },
    });
  };

  // update user
  const update = async (id, userData) => {
    const response = await axios.put(`/user/${id}`, userData);
    const { data: user } = response.data;

    dispatch({
      type: 'UPDATE',
      payload: {
        user,
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);
    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const forgotPassword = async (email) => {
    // const res = await axios.get(`forgot-password/${email}`);
    // console.log(res);
    try {
      const res = await axios.get(`forgot-password/${email}`);
      if (res.data) {
        return {
          success: true,
          message: 'Email sent',
          data: res.data,
        };
      }
    } catch (error) {
      return {
        success: false,
        message: error,
      };
    }
  };

  const verifyOTPCode = async (code) => {
    let token = sessionStorage.getItem('reset-pass-token');
    if (token) {
      try {
        // sessionStorage.removeItem('reset-pass-token');
        const res = await axios.post(`verify`, { code }, { headers: { Authorization: `Bearer ${token}` } });
        if (res.data) {
          return {
            success: true,
            message: 'Email sent',
          };
        }
      } catch (error) {
        return {
          success: false,
          message: error,
        };
      }
    } else {
      return {
        success: false,
        message: 'Please try again',
      };
    }
  };

  const updatePassword = async (data) => {
    let token = sessionStorage.getItem('reset-pass-token');
    if (token) {
      sessionStorage.removeItem('reset-pass-token');
      try {
        const response = await axios.put('', data, { headers: { Authorization: `Bearer ${token}` } });
        if (response.data) {
          const { token: accessToken, data: user } = response.data;
          console.log(response.data);

          setSession(accessToken);
          dispatch({
            type: 'LOGIN',
            payload: {
              user,
            },
          });

          return {
            success: true,
            message: 'Update Success',
          };
        }
      } catch (error) {
        console.log('Z Neechy');

        return {
          success: false,
          message: error,
        };
      }
    } else {
      return {
        success: false,
        message: 'Something went wrong',
      };
    }
  };

  const logout = async () => {
    const response = await axios.post('/logout', {});
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        update,
        forgotPassword,
        verifyOTPCode,
        updatePassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
