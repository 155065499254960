const ROLE_ENUM = ['super_admin', 'admin', 'user'];
export const ROLE = {
  SUPER_ADMIN: ROLE_ENUM[0],
  ADMIN: ROLE_ENUM[1],
  USER: ROLE_ENUM[2],
};

const ROLE_PRESENT = {
  [ROLE_ENUM[0]]: 'Super Admin',
  [ROLE_ENUM[1]]: 'Admin',
  [ROLE_ENUM[2]]: 'User',
};

export const getRoleNameByValue = (value) => {
  return ROLE_PRESENT[value];
};
