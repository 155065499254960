import { createSlice } from '@reduxjs/toolkit';
// utils
import { sub } from 'date-fns';

import axios from '../../utils/axios';
//
import { dispatch } from '../store';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  dateRange: {
    startDate: sub(new Date(), {
      days: 15,
    }),
    endDate: new Date(),
    key: 'selection',
  },
};

const slice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    setDateRange(state, action) {
      state.isLoading = false;
      state.dateRange = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { setDateRange } = slice.actions;

// * get app user
export function getClients(query, page, rowsPerPage, type) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      // if (query) {
      //   response = await axios.get('/users', { params: query });
      // } else {
      //   response = await axios.get('/users');
      // }
      // console.log('query', query)
      response = await axios.post('/clients', { query: query ? query : '',page, rowsPerPage, type });
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// * get app user by id
export function getAppUserById(id, body) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.post(`/user/${id}`, body);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// * create app user
export function createClient(data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.post('/clients/new', data);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
      // return error or throw error
      return {
        error,
      };
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// * update app user
export function updateAppUser(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.put(`/user/${id}`, data);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
      // return error or throw error
      return {
        error,
      };
    }

    // you can use the response here
    if (response?.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// * delete app user
export function deleteAppUser(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.delete(`/user/${id}`);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

// * update app user
export function updateClient(id, data) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.put(`/clients/${id}`, data);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
      // return error or throw error
      return {
        error,
      };
    }

    // you can use the response here
    if (response?.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}

export function deleteClient(id) {
  return async () => {
    dispatch(slice.actions.startLoading());
    let response;
    try {
      response = await axios.delete(`/clients/${id}`);
    } catch (error) {
      dispatch(slice.actions.stopLoading());
      dispatch(slice.actions.hasError(error));
    }

    // you can use the response here
    if (response.data) {
      dispatch(slice.actions.stopLoading());
      return response.data;
    }
  };
}


